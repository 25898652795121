var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('div',[_c('v-card',{class:_vm.isMobile ? '' : 'pa-4',attrs:{"flat":"","color":"transparent"}},[_c('v-card-text',[_c('v-card',{staticClass:"class-card card-shadow pa-6"},_vm._l((_vm.items.financeiro_parcelas),function(item,idx){return _c('v-row',{key:idx},[_c('v-col',{attrs:{"cols":"12"}},[(
                  item.financeiro_parcela_status_id ==
                  _vm.getFinanceiroParcelaStatusIdEmAberto() &&
                  _vm.faturaEmAtraso(item)
                )?_c('v-chip',{staticClass:"ma-1 redmain--text articulat-regular",staticStyle:{"background-color":"var(--v-red400)","border":"1px solid var(--v-redmain)"}},[_vm._v(" Fatura em atraso ")]):(
                  item.financeiro_parcela_status_id ==
                  _vm.getFinanceiroParcelaStatusIdEmAberto()
                )?_c('v-chip',{staticClass:"ma-1 blue300--text articulat-regular",staticStyle:{"border":"1px solid var(--v-blue300)"},attrs:{"color":"#FFFFFF"}},[_vm._v(" Fatura em aberto ")]):(
                  item.financeiro_parcela_status_id ==
                  _vm.getFinanceiroParcelaStatusIdPago()
                )?_c('v-chip',{staticClass:"ma-1 green900--text articulat-regular",staticStyle:{"background-color":"var(--v-green300)","border":"1px solid var(--v-green900)"}},[_vm._v(" Fatura paga ")]):_vm._e(),_c('v-chip',{staticClass:"ma-1 gray700--text articulat-regular",staticStyle:{"background-color":"var(--v-white)","border":"1px solid var(--v-gray100)"}},[_vm._v(" "+_vm._s(item.financeiro_categoria_nome)+" ")]),(item.financeiro_observacao)?_c('v-chip',{staticClass:"ma-1 gray700--text articulat-regular",staticStyle:{"background-color":"var(--v-white)","border":"1px solid var(--v-gray100)"}},[_vm._v(" "+_vm._s(item.financeiro_observacao)+" ")]):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"align-self":"center"}},[(
                      item.financeiro_parcela_status_id ==
                      _vm.getFinanceiroParcelaStatusIdEmAberto()
                    )?_c('span',{staticClass:"preco-vence articulat-medium pt-1",class:_vm.isMobile ? '' : 'pr-3'},[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.vencimento))+" ")]):_vm._e(),(_vm.isMobile)?_c('br'):_vm._e(),(
                      item.financeiro_parcela_status_id ==
                      _vm.getFinanceiroParcelaStatusIdEmAberto()
                    )?_c('span',{staticClass:"preco-fatura articulat-bold"},[_vm._v(" R$ "+_vm._s(_vm.formatPriceValue(item.valor_atualizado))+" ")]):_c('span',{staticClass:"preco-fatura articulat-bold"},[_vm._v(" R$ "+_vm._s(_vm.formatPriceValue(item.valor_pago))+" ")])]),(
                    item.financeiro_parcela_status_id ==
                    _vm.getFinanceiroParcelaStatusIdEmAberto()
                  )?_c('v-col',{staticClass:"text-end",class:_vm.isMobile ? '' : 'pr-8'},[(item.fatura_url)?_c('v-chip',{staticClass:"pa-5 rounded-xxl articulat-regular",staticStyle:{"box-shadow":"0px 10px 10px 0px #1a0a337a"},attrs:{"color":"blue850","dark":""},on:{"click":function($event){return _vm.showPix(item)}}},[_vm._v(" Abrir fatura ")]):_vm._e()],1):_vm._e()],1),_c('v-divider',{staticClass:"mt-6"})],1)],1)}),1)],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',[_vm._v("Pagamento")])]),_c('v-card-text',[_c('PaymentModal')],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"green"},on:{"click":_vm.confirmPayment}},[_vm._v("Confirmar")]),_c('v-btn',{attrs:{"color":"red"},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("Cancelar")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }